import './styles.css';

// window.onvar body = document.getElementsByTagName("body")[0];
var bios = document.querySelectorAll(".ccib_bio-pic");
var body = document.getElementsByTagName("body")[0];

[].forEach.call(bios, function(el) {
  el.addEventListener('click', function() {
    el.classList.toggle("ccib_bio--show");
    body.classList.toggle("ccib-scroll-lock");
  });
});

var menu = document.getElementById("ccib-mobile-menu");
var btn = document.getElementById("ccib-hamburger-button");

btn.addEventListener('click', function() {
  
  btn.classList.toggle("ccib-hamburger-button--open");
  btn.classList.toggle("ccib-hamburger-button--close");
  menu.classList.toggle("ccib-mobile-menu--open");
  menu.classList.toggle("ccib-mobile-menu--closed");
  body.classList.toggle("ccib-scroll-lock");

});
